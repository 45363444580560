.heading ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out, opacity 0s ease-in-out;
    opacity: 0;
}

.heading .expanded {
    max-height: 500px;
    opacity: 1;
    margin-right: -100px;
}

.heading .no_expand {
    max-height: 0;
    opacity: 0;
    position: fixed;
}

.sub-1 {
    color: white;
    transition: color 0.3s ease-in-out;
}
