@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

body {
  margin: 0;
  font-family: 'SF Pro Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* .Snowflake {
	display: inline-block;
	width: 0.1%;
	color: #FFFAFA;
	opacity: 0;
	margin: 0;
	padding: 0;
	animation: fall 16s linear infinite;
}

@keyframes fall {
	0% {
		opacity: 0;
	}
	3% {
		opacity: 0.9;
	}

	90% {
		opacity: 0.9;
	}
	100% {
		transform: translate(0, 97vh);
		opacity: 0;
	}
} */